<template>
  <div class="admin-performance-stats-table">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="filter-bar">
      <div>
        <b-input-group size="sm">
          <b-form-select v-model="environment" :options="environments">
          </b-form-select>
          <b-form-select v-model="metric" :options="metrics">
          </b-form-select>
<!--          <b-form-input-->
<!--            v-model="filter"-->
<!--            type="search"-->
<!--            id="filterInput"-->
<!--            placeholder="Type to Search"-->
<!--            debounce="500"-->
<!--            class="text-input"-->
<!--          ></b-form-input>-->
          <b-input-group-append>
            <b-button
              @click="loadData(append=false)"
              variant="primary"
            >Start</b-button
            >
          </b-input-group-append>
          <b-checkbox v-model="dateFilter" class="filter-label">Date Filter</b-checkbox>
        </b-input-group>
        <div v-if="dateFilter" class="filter-bar">
          <div class="advanced-filter">
            <div class="filter-label">Start</div>
            <b-form-timepicker v-model="startTime" class="text-input date-picker z-2"></b-form-timepicker>
            <b-form-datepicker v-model="startDate" class="text-input date-picker"></b-form-datepicker>
          </div>
          <div class="advanced-filter">
            <div class="filter-label">End</div>
            <b-timepicker v-model="endTime" class="text-input date-picker z-2"></b-timepicker>
            <b-datepicker v-model="endDate" class="text-input date-picker"></b-datepicker>
          </div>
        </div>
      </div>
    </div>

    <!--    Bootstrap Table-->
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      select-mode="single"
      selected-variant="success"
      td-class="admin-device-table-data"
      th-class="admin-device-table-header"
      ref="deviceTable"
    >
      <!--Select All Header Element-->
      <template v-slot:head(selected)="row">
        <b-form-checkbox
          v-model="selectAll"
          v-on:change="toggleSelectAll"
        ></b-form-checkbox>
      </template>
      <!--Select Checkbox Row Element-->
      <template v-slot:cell(selected)="row">
        <b-form-checkbox
          v-model="row.item.selected"
          v-on:change="selectChange(row.item, $event)"
        ></b-form-checkbox>
      </template>
    </b-table>
    <div class="page-bar">
      <b-form-select
        v-model="perPage"
        id="perPageSelect"
        size="sm"
        :options="pageOptions"
        class="page-select"
      >
      </b-form-select>
      <b-pagination
        v-model="currentPage"
        :total-rows="items.length"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="page-buttons"
      ></b-pagination>
      <b-button
        @click="loadData(append=true)"
        variant="primary"
        :disabled="!hasMore"
        v-b-tooltip="'Load More'"
      > <i class="fa fa-cloud-download"></i> </b-button
      >
    </div>
  </div>
</template>
<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import LoadingBox from '../helpers/LoadingBox'
import moment from 'moment'

export default {
  name: 'admin-performance-stats-table',
  components: {
    LoadingBox
  },
  props: {
  },
  data: function () {
    return {
      loading: true,
      // Table Filtering
      filterMethod: 'remote', // Filter Locally or remotely at the server-side
      // Table Pagination (local pagination - on the browser)
      currentPage: 1,
      totalRows: 0,
      perPage: 20,
      startKey: null,
      hasMore: false,
      pageOptions: [
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' }
      ],
      fields: [
        { key: 'statistic_name',
          label: 'Statistic Name',
          sortable: true
        },
        { key: 'datetime_utc',
          label: 'timestamp',
          sortable: true,
          formatter: this.formatTimestamp
        },
        { key: 'uuid',
          label: 'UUID',
          sortable: true
        },
        { key: 'value',
          label: 'Value',
          sortable: true
        }
      ],
      items: [],
      // Advanced Filtering Options
      environments: [
        'Production',
        'Development'
      ],
      metrics: [
        'messages_per_second',
        'messages_commit_retries',
        'messages_duplicates',
        'messages_in_count',
        'messages_out_count'
      ],
      environment: 'Production',
      metric: 'messages_per_second',
      dateFilter: false,
      startDate: Date(),
      startTime: '00:00',
      endDate: Date(),
      endTime: '23:59',
      loadingMoreDevices: false
    }
  },
  async created () {
    this.loading = false
  },
  methods: {
    loadData: async function (append = false) {
      let pagingOpts = {
        page_size: this.perPage
      }
      if (append) {
        pagingOpts.start_key = this.startKey
      }
      let result
      if (this.dateFilter) {
        let startDate = moment(this.startDate + ' ' + this.startTime)
        let endDate = moment(this.endDate + ' ' + this.endTime)
        if (isNaN(startDate) || isNaN(endDate)) {
          ErrorHelper.displayGeneralErrorToast('Please enter a valid Date/Time', 'Invalid Date or Time')
          return
        }
        result = await DataProvider.getAdminPerfStatsByDate(
          this.environment,
          this.metric,
          startDate.toISOString(),
          endDate.toISOString(),
          pagingOpts
        )
      } else {
        result = await DataProvider.getAdminPerfStats(this.environment, this.metric, pagingOpts)
      }
      console.log(result)
      if (result.success) {
        if (append) {
          console.log('Adding Items: ', result.data.items)
          this.items = this.items.concat(result.data.items)
        } else {
          console.log('settings items to :', result.data.items)
          this.items = result.data.items
        }
        this.startKey = result.data.start_key
        this.hasMore = result.data.next
      } else {
        ErrorHelper.displayDataErrorToast(result)
      }
      console.log('items: ', this.items)
    },
    formatTimestamp: function (timestamp) {
      return moment.utc(timestamp).format('YYYY-MM-DD HH:mm')
    }
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../variables";

.admin-performance-stats-table {
  // position: relative;
}

.filter-bar {
  display: flex;
  flex-direction: row;
}

.page-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.page-select {
  width: 10%;
  min-width: 50px;
}

.page-buttons {
  width: 25%;
  min-width: 150px;
}

.row-action {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.advanced-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px;
  padding: 3px;
  background: $theme-color-background-1;
  border: 1px solid $theme-color-primary-3;
  z-index: 2;
}

.filter-label {
  font-family: "Open Sans", sans-serif;
  color: $theme-color-primary-3;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0px 5px;
}

.sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  height: calc(1.5em + 0.5rem + 2px);
}

.z-2 {
  z-index: 10;
}

.b-form-btn-label-control.form-control > label{
  color: white;
}
</style>
