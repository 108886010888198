var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-performance-stats-table" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "filter-bar" }, [
        _c(
          "div",
          [
            _c(
              "b-input-group",
              { attrs: { size: "sm" } },
              [
                _c("b-form-select", {
                  attrs: { options: _vm.environments },
                  model: {
                    value: _vm.environment,
                    callback: function($$v) {
                      _vm.environment = $$v
                    },
                    expression: "environment"
                  }
                }),
                _c("b-form-select", {
                  attrs: { options: _vm.metrics },
                  model: {
                    value: _vm.metric,
                    callback: function($$v) {
                      _vm.metric = $$v
                    },
                    expression: "metric"
                  }
                }),
                _c(
                  "b-input-group-append",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.loadData((_vm.append = false))
                          }
                        }
                      },
                      [_vm._v("Start")]
                    )
                  ],
                  1
                ),
                _c(
                  "b-checkbox",
                  {
                    staticClass: "filter-label",
                    model: {
                      value: _vm.dateFilter,
                      callback: function($$v) {
                        _vm.dateFilter = $$v
                      },
                      expression: "dateFilter"
                    }
                  },
                  [_vm._v("Date Filter")]
                )
              ],
              1
            ),
            _vm.dateFilter
              ? _c("div", { staticClass: "filter-bar" }, [
                  _c(
                    "div",
                    { staticClass: "advanced-filter" },
                    [
                      _c("div", { staticClass: "filter-label" }, [
                        _vm._v("Start")
                      ]),
                      _c("b-form-timepicker", {
                        staticClass: "text-input date-picker z-2",
                        model: {
                          value: _vm.startTime,
                          callback: function($$v) {
                            _vm.startTime = $$v
                          },
                          expression: "startTime"
                        }
                      }),
                      _c("b-form-datepicker", {
                        staticClass: "text-input date-picker",
                        model: {
                          value: _vm.startDate,
                          callback: function($$v) {
                            _vm.startDate = $$v
                          },
                          expression: "startDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "advanced-filter" },
                    [
                      _c("div", { staticClass: "filter-label" }, [
                        _vm._v("End")
                      ]),
                      _c("b-timepicker", {
                        staticClass: "text-input date-picker z-2",
                        model: {
                          value: _vm.endTime,
                          callback: function($$v) {
                            _vm.endTime = $$v
                          },
                          expression: "endTime"
                        }
                      }),
                      _c("b-datepicker", {
                        staticClass: "text-input date-picker",
                        model: {
                          value: _vm.endDate,
                          callback: function($$v) {
                            _vm.endDate = $$v
                          },
                          expression: "endDate"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _c("b-table", {
        ref: "deviceTable",
        attrs: {
          striped: "",
          hover: "",
          items: _vm.items,
          fields: _vm.fields,
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          "select-mode": "single",
          "selected-variant": "success",
          "td-class": "admin-device-table-data",
          "th-class": "admin-device-table-header"
        },
        scopedSlots: _vm._u([
          {
            key: "head(selected)",
            fn: function(row) {
              return [
                _c("b-form-checkbox", {
                  on: { change: _vm.toggleSelectAll },
                  model: {
                    value: _vm.selectAll,
                    callback: function($$v) {
                      _vm.selectAll = $$v
                    },
                    expression: "selectAll"
                  }
                })
              ]
            }
          },
          {
            key: "cell(selected)",
            fn: function(row) {
              return [
                _c("b-form-checkbox", {
                  on: {
                    change: function($event) {
                      return _vm.selectChange(row.item, $event)
                    }
                  },
                  model: {
                    value: row.item.selected,
                    callback: function($$v) {
                      _vm.$set(row.item, "selected", $$v)
                    },
                    expression: "row.item.selected"
                  }
                })
              ]
            }
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "page-bar" },
        [
          _c("b-form-select", {
            staticClass: "page-select",
            attrs: {
              id: "perPageSelect",
              size: "sm",
              options: _vm.pageOptions
            },
            model: {
              value: _vm.perPage,
              callback: function($$v) {
                _vm.perPage = $$v
              },
              expression: "perPage"
            }
          }),
          _c("b-pagination", {
            staticClass: "page-buttons",
            attrs: {
              "total-rows": _vm.items.length,
              "per-page": _vm.perPage,
              align: "fill",
              size: "sm"
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          }),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip",
                  value: "Load More",
                  expression: "'Load More'"
                }
              ],
              attrs: { variant: "primary", disabled: !_vm.hasMore },
              on: {
                click: function($event) {
                  return _vm.loadData((_vm.append = true))
                }
              }
            },
            [_c("i", { staticClass: "fa fa-cloud-download" })]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }