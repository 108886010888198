<template>
  <div class="admin-performance-stats">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>System Performance</h2></b-card-header>
        <b-card-body>
          <admin-performance-stats-table></admin-performance-stats-table>
        </b-card-body>
      </b-card>
    </div>

  </div>
</template>

<script>
import AdminPerformanceStatsTable from '@/components/admin/AdminPerformanceStatsTable'

export default {
  name: 'admin-performance-stats',
  components: {
    AdminPerformanceStatsTable
  },
  data: function () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    clickBack: function () {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';
.admin-performance-stats {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2%;
  overflow-y: auto;
}

.toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 3rem;
}
</style>
